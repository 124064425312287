import styled from "styled-components";

const DisclaimerText = styled.div`
	font-weight: bold;
	font-size: 12px;
	font-style: italic;
	margin-top: 11px;
`;


export default DisclaimerText;
