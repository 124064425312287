import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { Content } from "./generic/genericPage.js";
import GenericPostPageWrapper, { GenericPostTemplate } from "./generic/genericPostTemplate";
import AmazonProductLink from "../components/AmazonProductLink/AmazonProductLink";
import DisclaimerText from "../components/DisclaimerText/DisclaimerText";

const PageContent = ({ page }) => (
	<Fragment>
		<Content content={page.content} />

		{page.amazonId ?
			<AmazonProductLink productId={page.amazonId} /> : null}

		{page.showDisclaimer ?
			<DisclaimerText>* למונטסורי בייבי אין קשר לספקים והאחריות לרכישת המוצר המוזכר הינה של הלקוח
				בלבד.</DisclaimerText> :
			<DisclaimerText>* מוצרי האונליין משותפים עם הקונה לזמן מוגבל</DisclaimerText>}
	</Fragment>);

export const ProductPageTemplate = ({ page }) =>
	<GenericPostTemplate page={page} siteTitle="PREVIEW">
		{PageContent}
	</GenericPostTemplate>;

export default GenericPostPageWrapper({
	type: "מוצרים",
	sectionPath: "/products",
	showSimilar: true,
	similarProductsTitle: "מוצרים נוספים מומלצים",
	children: PageContent
});

export const pageQuery = graphql`
  query ProductByIdQuery($id: String!, $tags: [String]) {
				...SiteTitle
    markdownRemark(id: {eq: $id }) {
		id
    html
		frontmatter {
			date(formatString: "MMMM DD, YYYY")
			title
			description
			descriptionRaw
			tags
			templateKey
			buyLink
			amazonId
			postId
			showDisclaimer
		}
    fields {
			slug
		}
		...RemoteBannerImage
	}
  similarProducts: allMarkdownRemark(
      sort: {order: [DESC], fields: [frontmatter___date]},
      filter: {frontmatter: { allSaleIndex: { eq: true }, tags: {in: $tags} } },
      limit: 4
  ) {
      edges {
          ...AllSaleItem
      }
  }
}`;
