import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "grommet";
import { getColor } from "../../theme";

//TODO move to site settings!!
const AMAZON_AFFILIATE_ID = "montessorib00";

const AmazonFrame = styled.iframe`
	width:120px;
	height:240px;
`;

const getAmazonProductLinkUrl = (pid, titleColor) =>
	`https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&
	OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&
	ad_type=product_link&tracking_id=${AMAZON_AFFILIATE_ID}&marketplace=amazon&
	region=US&placement=${pid}&asins=${pid}&
linkId=9539dae2cafbe4b0962466ba096097bc&show_border=false&
link_opens_in_new_window=true&price_color=333333&title_color=${titleColor.replace("#", "")}&bg_color=ffffff`;

const AmazonProductLink = ({ productId, className }) => {
	const theme = useContext(ThemeContext);

	return <AmazonFrame
		className={className}
		scrolling="no"
		frameBorder="0"
		allowtransparency="true"
		src={getAmazonProductLinkUrl(productId, getColor(theme, "brand"))} />;
};

export default AmazonProductLink;
